import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import "./graduated.css";
import Logo from "../../assets/img/Membrete_Header.png";
import Footer from "../../assets/img/Membrete_Footer.png";
import Firma from "./firma.png";
import axios from "../../api";
import { connect } from "react-redux";
import { decrypt } from "../../utils/crypt";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function Certificate(props) {
  const { token } = props;
  const history = useHistory();
  const [graduated, setGraduated] = useState({});

  const fetch = async () => {
    let { data } = await axios.post(
      `/graduated/title/${decrypt(props.match.params.id)}`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setGraduated(data?.graduatedTitle);
  };

  useEffect(() => {
    if (props.match.params.id && props.match.params.certificateId) {
      fetch();
    } else {
      history.push("/");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Paper elevation={0}>
      <div
        style={{
          width: "auto",
          backgroundColor: "#ffffff",
          textAlign: "center",
          fontFamily: "Times New Roman",
        }}
      >
        <div style={{ margin: "0px 60px" }}>
        <p
              className="header"
              style={{
                fontSize: "16px",
              }}
            >
              <img
                src={Logo}
                style={{
                  maxWidth: "100%",
                }}
                alt="Logo Cuc"
              />
            </p>
          {/*<i>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                color: "#0C0707",
                textAlign: "center",
              }}
            >
              VIGILADA MINEDUCACIÓN
            </h1>
          </i>
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "17px",
              color: "#0C0707",
              textAlign: "center",
            }}
          >
            CORPORACIÓN UNIVERSIDAD DE LA COSTA, CUC
          </h1>
          <h1
            style={{
              fontSize: "14px",
              color: "#0C0707",
              textAlign: "center",
            }}
          >
            PERSONERIA JURIDICA No. 352 ABRIL 1971
            <br />
            BARRANQUILLA-COLOMBIA
          </h1>*/}
          <br />
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "17px",
              color: "#0C0707",
              textAlign: "center",
            }}
          >
            EL SUSCRITO SECRETARIO GENERAL DE LA CORPORACION
            <br />
            UNIVERSIDAD DE LA COSTA, CUC
          </h1>
          <br />
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "17px",
              color: "#0C0707",
              textAlign: "center",
            }}
          >
            CERTIFICA
          </h1>
          <br />

          <p
            style={{
              marginTop: "20px",
              fontSize: "16px",
              textAlign: "justify",
            }}
          >
            El(la) señor(a){" "}
            <b>
              {`${graduated?.egresados?.primer_nombre} ${graduated?.egresados?.segundo_nombre} ${graduated?.egresados?.primer_apellido} ${graduated?.egresados?.segundo_apellido}`.toUpperCase()}
            </b>{" "}
            identificado(a) con{" "}
            {graduated?.egresados?.vp_tipo_identificacion === 222
              ? "Cédula de extranjería "
              : "Cédula de ciudadanía "}
            número{" "}
            <b>
              {parseInt(graduated?.egresados?.identificacion)?.toLocaleString()}
            </b>{" "}
            de <b>{graduated?.egresados?.ciudades?.nombre}</b>, aparece en
            nuestros registros como graduado de nuestra institución con el
            título de <b>{graduated?.Titulo?.nombre.toUpperCase()}</b> con el
            Acta de Grado No.{" "}
            {graduated?.numero_acta?.length > 0 &&
              `${"0".repeat(
                3 - `${graduated?.numero_acta}`.length > 0
                  ? 3 - `${graduated?.numero_acta}`.length
                  : 0
              )}${graduated?.numero_acta}`}{" "}
            de la fecha{" "}
            {new Date(`${graduated?.fecha_grado} `).toLocaleDateString(
              "es-ES",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
            .
          </p>
          <p style={{ textAlign: "left" }}>Atentamente,</p>
          <b>
            <p
              style={{
                marginTop: "40px",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              <img src={Firma} alt="firma" style={{ maxWidth: "20rem" }} />
            </p>
            <p
              className="footer"
              style={{
                fontSize: "16px",
              }}
            >
              <img
                src={Footer}
                style={{
                  maxWidth: "100%",
                }}
                alt="footer"
              />
            </p>
          </b>
        </div>
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(Certificate);
